import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';
import {
  selectGroup,
  selectAppData,
  selectGroupFetchStatus,
} from 'store/selectors';

import { Box } from 'wui/Box';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';

import { GroupDetails } from 'Group/Widget/Header/GroupDetails';
import settingsParams from '../settingsParams';

import { Feed } from './Feed';
import { FEED_WIDGET_WRAPPER } from './dataHooks';

export const Widget = () => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { group$, feed$ } = useController();

  const limit = settings.get(settingsParams.postsCount);

  const { groupId } = useSelector(selectAppData);
  const group = useSelector(selectGroup(groupId));
  const { loading } = useSelector(selectGroupFetchStatus(groupId));

  useDidUpdate(() => {
    group$.fetch(groupId);
  }, [groupId]);

  useDidUpdate(() => {
    if (group.id) {
      feed$.fetch(group.id, { limit }, !isMobile);
    }
  }, [group.id, group.membership, limit]);

  if (loading) {
    return <EmptyState title={<Spinner />} />;
  }

  if (!group.id) {
    return (
      <EmptyState
        title={t('groups-web.feed-widget.empty.title')}
        subtitle={t('groups-web.feed-widget.empty.description')}
      />
    );
  }

  return (
    <Box gap="SP5" direction="vertical" data-hook={FEED_WIDGET_WRAPPER}>
      <GroupDetails groupId={group.id} />
      <Feed groupId={group.id} />
    </Box>
  );
};

Widget.displayName = 'Widget';
